@import "../../main/style.scss";

.course {
	width: 100%;
	height: auto;
	@include display-center(flex-start, center);
	background-color: #b41d893a;
	flex-direction: column;	
	padding: 40px 100px;
	margin-bottom: 100px;


	&__title {
		@include font($black, 48px, 700, normal, normal);
	}

	&__secondary-description {
		@include font($black, 20px, 400, normal, normal);
		text-align: center;
		
		margin-top: 25px;
	}

	&__buttons {
		margin-top: 50px;
	}
	
	.swiper {
		width: 100%;
		height: 450px;
	}
	
	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
		background-color: $main !important;
	}

	.mySwiper .swiper-button-next,
	.mySwiper .swiper-button-prev {
	  width: 60px; 
	  height: 60px; 
	  background-color: $main; 
	  border-radius: 50%; 
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  @include font($white, 24px, 700, normal, normal);
	  top: 50px; 
	}
	
	
	.mySwiper .swiper-button-prev::after {
		content: '';
		width: 50px;
		height: 50px;
		background-image: url(../img/arrow__left.svg);
		background-size: cover;
	}

	.mySwiper .swiper-button-next::after {
		content: '';
		width: 50px;
		height: 50px;
		background-image: url(../img/arrow__rigth.svg);
		background-size: cover;
	}

	&__box {
		width: 100%;
		height: auto;
		@include display-center(center, center);
		flex-wrap: wrap;
		position: relative;

		&-card {
			width: 450px;
			height: 250px;
			background-color: #fff;
			filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25));
			border-radius: 5px;
			margin: 20px;
			display: flex;
			padding-top: 20px;
			padding-left: 25px;
			overflow: hidden;
			cursor: pointer;
			position: relative;

			&:hover {
				.course-hover {
					@include display-center(center, center);
					animation-name: courshover;
					animation-duration: 0.6s;
					animation-iteration-count: 1;
					@keyframes courshover {
						0% {
							opacity: 0;
						}
						100% {
							opacity: 1;
						}
					}
					-webkit-transition: 0.5s;
					transition: 0.5s;
				}
			}

			.course-hover {
				width: 100%;
				height: 100%;
				background: rgba(180, 29, 138, 0.90);
				backdrop-filter: blur(2px);
				position: absolute;
				left: 0%;
				top: 0%;
				z-index: 2;
				-webkit-transition: 0.5s;
				transition: 0.5s;
				@include display-center(center, center);
				display: none;

				button {
					width: 100% !important;
					padding: 30px;
					background-color: #fff;
					color: $main;
				}
			}

			.course__left {
				width: 60%;
				height: 100%;


				&-button {
					border: none;
					padding: 8px 34px;
					@include font($white, 20px, 600, normal, normal);
					margin-top: 10px;
					margin-bottom: 20px;
				}

				&-subtitle {
					@include font($black, 16px, 500, normal, normal);
					margin-top: 20px;
				}

				&-title {
					@include font($black, 24px, 600, normal, normal);
					margin-top: 10px;
					padding-bottom: 10px
				}

				&-box {
					@include display-center(flex-start, center);
					margin-top: 5px;
					.course__description {
						@include font($black, 16px, 500, normal, normal);
						margin-left: 5px;
					}
				}
			}
 
			.course__rigth {
				width: 40%;
				height: 100%;
				@include display-center(flex-end, center);

				&-img {
					width: 200px;
					height: 200px;
					background-size: cover;
					position: relative;
					left: 10px;
				}

				&-img1 {
					background-image: url(../img/img1.webp);
				}

				&-img2 {
					background-image: url(../img/img2.webp);
					position: absolute;
					left: 65%;
				}

				&-img3 {
					background-image: url(../img/img3.webp);
				}

				&-img4 {
					background-image: url(../img/img4.webp);
				}

				&-img5 {
					background-image: url(../img/img5.webp);
				}
				
				&-img6 {
					background-image: url(../img/img6.webp);
				}

				&-img7 {
					background-image: url(../img/img7.webp);
				}

				&-img8 {
					background-image: url(../img/img8.webp);
				}
				
				&-img9 {
					background-image: url(../img/img9.webp);
				}

				&-img10 {
					background-image: url(../img/img10.webp);
				}

				&-img11 {
					background-image: url(../img/img11.webp);
				}

				&-img12 {
					background-image: url(../img/img12.webp);
				}

				&-img13 {
					background-image: url(../img/img13.webp);
				}
				&-img14 {
					background-image: url(../img/img14.webp);
				}
		

			}


		}
	}

}

@media screen and (max-width: 1350px) {
	.course {
		padding: 60px 60px;
		&__title {
			@include font($black, 42px, 700, normal, normal);
		}
	}

}

@media screen and (max-width: 1200px) {
	.course {
		padding: 60px 80px;
		&__title {
			@include font($black, 36px, 700, normal, normal);
		}
	}

}

@media screen and (max-width: 1000px) {
	.course {
		padding: 100px 60px;
	}

}

@media screen and (max-width: 700px) {
	.course {
		padding: 60px 30px;
	
		&__title {
			@include font($black, 32px, 700, normal, normal);
		}

		&__secondary-description {
			@include font($black, 18px, 400, normal, normal);
		}
	}

}

@media screen and (max-width: 530px) {

	

	.course {
		padding: 40px 30px;
		&__title {
			@include font($black, 28px, 700, normal, normal);
		}

		.swiper {
			width: 100%;
			height: 600px;
		}
	

		&__box {
			width: 100%;
			height: auto;
			@include display-center(center, center);
			flex-wrap: wrap;
	
			&-card {
				width: 100%;
				height: auto;
				margin: 20px;
				padding: 20px;
				cursor: pointer;
				flex-direction: column;

				.course-hover {
					button {
						width: 100% !important;
						padding: 15px;
						background-color: #fff;
						font-size: 16px;
						color: $main;
					}
				}
	
				.course__left {
					width: 100%;
					height: auto;
					@include display-center(flex-start, center);
					flex-direction: column;
	
					&-button {
						@include font($white, 14px, 600, normal, normal);
	
					}
	
					&-subtitle {
						@include font($black, 14px, 500, normal, normal);
					}
	
					&-title {
						@include font($black, 20px, 600, normal, normal);
					}
	
					&-box {
						margin-top: 5px;
						.course__description {
							@include font($black, 14px, 500, normal, normal);
							margin-left: 3px;
							text-align: center;
						}
					}
				}
	 
				.course__rigth {
					width: 100%;
					height: auto;
					@include display-center(center, center);

					&-img {
						width: 180px;
						height: 180px;
					}

					&-img2 {
						background-image: url(../img/img2.webp);
						position: static;
					}
					
					&-img4 {
						background-image: url(../img/img4.webp);
						height: 185px ;
					}
				}
			}
		}
	}

}