@import "../../main/style.scss";




.first-lesson {
	width: 100%;
	height: auto;
	background-size: cover;
	@include display-center(center, center);
	flex-direction: column;
	padding: 0 100px;
	overflow: hidden;
	position: relative;
	margin-bottom: 100px;
	overflow: hidden;
	@include font($black, 32px, 700, normal, normal);	


	.reg-form {
		width: 100% !important;
		height: auto;
		z-index: 10;
		overflow: hidden;
		@include display-center(center, center);

	}


	iframe {
		width: 100% !important;
		height: 800px;
		z-index: 10;
	}

}


@media screen and (max-width: 1350px) {
	.first-lesson {
		padding: 0 60px;
		&__title {
			@include font($white, 42px, 700, normal, normal);
		}
	}

}

@media screen and (max-width: 1200px) {
	.first-lesson {
		padding: 0 80px;
		&__title {
			@include font($white, 36px, 700, normal, normal);
		}
	}

}

@media screen and (max-width: 1000px) {
	.first-lesson {
		padding: 0 60px;
		&__box {
			width: 100%;
			margin-top: 15px;
		}
	}

}

@media screen and (max-width: 700px) {
	.first-lesson {
		padding: 0 30px;

		iframe {
			height: 900px;
		}
		
		&__title {
			@include font($white, 32px, 700, normal, normal);
		}
		&__box {
			width: 75%;
			margin-top: 50px;
			z-index: 2;
			
			&-input {
				width: 100%;
				display: flex;
				gap: 30px;
				flex-direction: column;
				.first-lesson-input--first {
					width: 100%;
				}
			}
			
		}

	}

}

@media screen and (max-width: 530px) {
	.first-lesson {
		iframe {
			height: 1000px;
		}
		
		&__title {
			@include font($white, 28px, 700, normal, normal);
		}
		&__box {
			width: 100%;
			
			&-input {
				width: 100%;
			}
			.first-lesson-input--first {
					width: 100%;

			}
		}
		
	}
}