@import "../../main/style.scss";

.terms {
	&__main {
		width: 100%;
		height: auto;
		padding: 80px 100px;
		&-title {
			@include font($black, 32px, 700, normal, normal);	
			margin-top: 35px
		}

		&-description {
			@include font($black, 24px, 400, normal, normal);
			margin: 25px 0;
		}

		&-description2 {
			margin-left: 35px;
		}
		

		&-email {
			@include font($black, 24px, 400, normal, normal);
			margin: 45px 0;
		}

		&-link {
			color: $main;
			font-weight: 500;
			border-bottom: 1px solid $main;
		}

		.terms-margin {
			margin-top: 15px;
		}
	}
}

@media screen and (max-width: 1350px) {
	.terms {
		&__main {
			padding: 100px 80px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.terms {
		&__main {
			padding: 80px 60px;
			&-title {
				@include font($black, 28px, 700, normal, normal);	
				margin-top: 30px
			}

			&-description {
				@include font($black, 20px, 400, normal, normal);
				margin: 20px 0;
			}

			&-email {
				@include font($black, 20px, 400, normal, normal);
				margin: 35px 0;
			}
		}
	}
}
@media screen and (max-width: 700px) {
	.terms {
		&__main {
			padding: 60px 30px;

			&-title {
				@include font($black, 24px, 700, normal, normal);	
				margin-top: 30px
			}

			&-description {
				@include font($black, 18px, 400, normal, normal);
				margin: 15px 0;
			}

			&-email {
				@include font($black, 16px, 400, normal, normal);
				margin: 30px 0;
			}
		}
	}
}