@import "../../main/style.scss";

.feedback {
	width: 100%;
	height: auto;
	padding: 100px 100px;
	background-image: url(../img/bg.svg);
	background-size: cover;
	flex-direction: column;
	@include display-center(center, center);
	margin-bottom: 100px;
	position: relative;
	overflow: hidden;

	&__title {
		@include font($white, 48px, 700, normal, normal);
		z-index: 1;	
		text-align: center;
	}

	&__box {
		width: 100%;
		margin-top: 50px;
		z-index: 1;
		@include display-center(center, center);
		flex-wrap: wrap;
		gap: 20px;
		&-cart {
			width: 300px;
			height: 300px;
			background-size: cover;
			border-radius: 10px;
			transition: 0.3s;

			&:hover {
				transform: scale(1.5);
				transition: 0.3s;
			}

		}

		&-cart1 {
			background-image: url(../img/feedback1.webp);
		}

		&-cart2 {
			background-image: url(../img/feedback2.webp);
		}

		&-cart3 {
			background-image: url(../img/feedback3.webp);
		}

		&-cart4 {
			background-image: url(../img/feedback4.webp);
		}

	}

	&::after {
		content: "";
		width: 100%;
		height: 100%;
		background-image: url(../img/elipse.svg);
		background-size: cover;
		position: absolute;
		top: 40%;
		left: 40%;
		z-index: 0;
	}

}


@media screen and (max-width: 1350px) {
	.feedback {
		padding: 	100px 60px;
		&__title {
			@include font($white, 42px, 700, normal, normal);
		}
		
	&__box {
		gap: 40px;
		&-cart {
			width: 400px;
			height: 400px;
		}
	  }
	}

}

@media screen and (max-width: 1200px) {
	.feedback {
		padding: 100px 80px;

		&__title {
			@include font($white, 36px, 700, normal, normal);
		}
	}
}

@media screen and (max-width: 1000px) {
	.feedback {
		padding: 100px 60px;
		&__box {
			gap: 30px;
			&-cart {
				width: 600px;
				height: 600px;
			}
		  }
	}
}

@media screen and (max-width: 700px) {
	.feedback {
		padding: 100px 30px;
		&__title {
			@include font($white, 32px, 700, normal, normal);
		}
		&__box {
			&-cart {
				width: 400px;
				height: 400px;
			}
		  }

		&::after {
			content: "";
			width: 100%;
			height: 100%;
			background-image: url(../img/elipse.svg);
			background-size: cover;
			position: absolute;
			top: 0%;
			left: 0%;
			z-index: 0;
		}
	}
}

@media screen and (max-width: 530px) {
	.feedback {
		&__title {
			@include font($white, 28px, 700, normal, normal);
		}
		&__box {
			&-cart {
				width: 300px;
				height: 300px;

				&:hover {
					transform: scale(1.5);
				}
			}
		  }
	}
}

@media screen and (max-width: 460px) {
	.feedback {
		&__box {
			&-cart {
				&:hover {
					transform: scale(1.2);
				}
			}
		  }
	}
}