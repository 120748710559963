@import "../../main/style.scss";

.about-us {
	width: 100%;
	height: auto;
	@include display-center(flex-start, center);
	flex-direction: column;
	background-image: url(../img/bg.svg);
	background-size: cover;
	padding: 100px 100px;
	margin-bottom: 100px;
	position: relative;
	overflow: hidden;

	&__title {
		@include font($white, 48px, 700, normal, normal);
		z-index: 1;	
	}
	
	&__description {
		@include font($white, 24px, 400, normal, normal);	
		margin-top: 25px;
		text-align: center;
		z-index: 1;	

	}

	&__video {
		width: 100%;
		height: auto;
		margin-top: 50px;
		@include display-center(center, center);
		z-index: 2 !important;	

		iframe {
			width: 100%;
			height: 1000px;
		}
	}

	&::after {
		content: "";
		width: 140%;
		height: 140%;
		background-image: url(../img/bg__line.svg);
		background-size: cover;
		position: absolute;
		z-index: 0;
	}
}


@media screen and (max-width: 1500px) {
	.about-us {
		&__video {
			iframe {
				width: 100%;
				height: 700px;
			}
		}
	}
}

@media screen and (max-width: 1350px) {
	.about-us {
		padding: 100px 60px;
		&__title {
			@include font($white, 42px, 700, normal, normal);
		}
	}

}

@media screen and (max-width: 1200px) {
	.about-us {
		padding: 100px 80px;
		&__title {
			@include font($white, 36px, 700, normal, normal);
		}
	}

}

@media screen and (max-width: 1000px) {
	.about-us {
		padding: 100px 60px;
		&__video {
			iframe {
				width: 100%;
				height: 550px;
			}
		}
	}

}

@media screen and (max-width: 700px) {
	.about-us {
		padding: 100px 30px;
		&__title {
			@include font($white, 32px, 700, normal, normal);
		}

		&__description {
			@include font($white, 20px, 400, normal, normal);	
		}

		&__video {
			iframe {
				width: 100%;
				height: 50vh;
			}
		}
	}

}

@media screen and (max-width: 530px) {
	.about-us {
		padding: 100px 0;
		&__title {
			@include font($white, 28px, 700, normal, normal);
		}
		&__description {
			@include font($white, 20px, 400, normal, normal);	
			padding: 0 30px;
		}
		&__video {
			iframe {
				width: 330px;
				height: 187px;
			}
		}
	}
}



