@import "../../main/style.scss";


.start {
	width: 100%;
	height: 100%;
	padding: 0 100px;
	margin-bottom: 100px;

	&__title {
		@include font($black, 48px, 700, normal, normal);
		text-align: center;
	}

	&__box {
		width: 100%;
		height: auto;
		margin-top: 50px;
		@include display-center(space-between, center);
		@include font($grey, 32px, 400, normal, normal);
		text-align: center;

		&-elipce {
		@include display-center(flex-start, center);
		flex-direction: column;
			.start__round {
				width: 120px;
				height: 120px;
				background-color: $main;
				border-radius: 50%;
				@include display-center(center, center);
			}
			p {
				margin-top: 10px;
			}
		}
	}

	&__payment {
		width: 100%;
		height: auto;
		@include display-center(center, center);
		margin-top: 35px;

		&-img {
			width: 800px;
			height: 72px;
			background-image: url(../img/playent.webp);
			background-size: cover;
		}
	}

	&__buttons {
		width: 100%;
		height: auto;
		@include display-center(center, center);
		gap: 30px;
		margin-top: 50px;
	}


}

@media screen and (max-width: 1350px) {
	.start {
		padding: 0 60px;
		@include display-center(flex-start, center);
		flex-direction: column;
		&__title {
			@include font($black, 42px, 700, normal, normal);
		}
		&__box {
			width: 800px;
			height: auto;
			margin-top: 50px;
			@include display-center(center, center);
			@include font($grey, 32px, 400, normal, normal);
			text-align: center;
			flex-wrap: wrap;

			&-arrow {
				display: none;
			}

			&-elipce {
			width: 200px; 
			@include display-center(flex-start, center);
			flex-direction: column;
			margin: 20px;
			margin-top: 0px;
		
				.start__round {
					width: 120px;
					height: 120px;
					background-color: $main;
					border-radius: 50%;
					@include display-center(center, center);
				}
				p {
					margin-top: 10px;
				}
			}
		}
	
	}

}

@media screen and (max-width: 1200px) {
	.start {
		padding: 0 80px;
		&__title {
			@include font($black, 36px, 700, normal, normal);
		}
	}
}

@media screen and (max-width: 1000px) {
	.start {
		padding: 0 60px;
		
		&__box {
			width: auto;
			margin-top: 35px;
			flex-direction: column;

			.arrow {
				transform: rotate(90deg);
			}
			&-elipce {
				margin: 20px;
			}
			&-arrow {
				display: block;
			}

		}

		&__payment {
			&-img {
				width: 650px;
				height: 45px;
				background-image: url(../img/playent.webp);
				background-size: cover;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.start {
		padding: 0 30px;
		&__title {
			@include font($black, 32px, 700, normal, normal);
		}

		&__box {
			width: 100%;
			height: auto;
			margin-top: 50px;
			@include display-center(space-between, center);
			@include font($grey, 32px, 400, normal, normal);
			text-align: center;
	
			&-elipce {
			@include display-center(flex-start, center);
			flex-direction: column;
				.start__round {
					width: 80px;
					height: 80px;
					background-color: $main;
					border-radius: 50%;
					@include display-center(center, center);
					svg {
						width: 50px;
						height: 50px;
					}
				}
				p {
					margin-top: 10px;
				}
			}
		}
		.arrow {
			width: 100px;
		}

		
		&__payment {
			&-img {
				width: 450px;
				height: 30px;
				background-image: url(../img/playent.webp);
				background-size: cover;
			}
		}
		&__buttons {
			width: 100%;
			height: auto;
			@include display-center(center, center);
			gap: 20px;
			margin-top: 50px;
			flex-direction: column;

		
		}
	
	}
}

@media screen and (max-width: 530px) {
	.start {
		&__title {
			@include font($black, 28px, 700, normal, normal);
		}

		&__box {
			margin-top: 10px;
		}

		&__payment {
			&-img {
				width: 300px;
				height: 20px;
				background-image: url(../img/playent.webp);
				background-size: cover;
			}
		}
		
		&__buttons {
			a {
				width: 100%;
			}
			div {
				width: 100%;
			}
			&-button {
				width: 100% !important;
			}
		}
	}
}