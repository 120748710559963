@import "../../main/style.scss";


a {
	text-decoration: none;
	color: $black;
}



.CoursePage {
	width: 100%;
	height: auto;
	padding: 80px 100px;
	padding-top: 20px;
	@include display-center(center, center);

	&__form {
		width: 100%;
		@include display-center(flex-start, center);
		flex-direction: column;
		margin-bottom: 140px;
		form {
			@include display-center(flex-start, center);
			flex-direction: column
		}

		&-title {
			@include font($black, 48px, 600, normal, normal);
			margin-bottom: 30px;
			text-align: center;
		}

		&-input {
			margin-top: 20px;

			p {
				margin-bottom: 10px;
				@include font($black, 20px, 400, normal, normal);

			}

			input {
				width: 700px;
				height: 70px;
				@include display-center(flex-start, center);
				@include font($main, 20px, 700, normal, normal);
	
				padding: 0 20px;
				border: none;
				border-radius: 5px;
				box-shadow: 0px 4px 7px rgb(205, 205, 205);
				&::placeholder {
					color: $main;
				}
	
			}

			textarea {
				width: 700px;
				height: 200px;
				@include display-center(flex-start, center);
				@include font($main, 20px, 700, normal, normal);
	
				padding: 20px;
				border: none;
				border-radius: 5px;
				box-shadow: 0px 4px 7px rgb(205, 205, 205);
				resize: none; 
				&::placeholder {
					color: $main;
				}
			}

			.form__error {
				margin-top: 10px;
				@include font(red, 18px, 400, normal, normal);
			}
		}
	}

	iframe {
		width: 100% !important;
		height: 1100px;
		z-index: 10;
	}

	&__buttons {
		width: 100%;
	}

	&__left {
		width: 60%;
		padding-right: 35px;
		&-title {
			@include font($main, calc(10px + 2vw), 600, normal, normal); // Початковий розмір шрифта + 2% ширини видимого вікна
		}
		  

		&-description {
			@include font($black, 24px, 500, normal, normal);
			margin-top: 25px;
		}

		&-box {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			margin-top: 50px;
			gap: 30px;
			
			.CoursePage__item {
				@include display-center(flex-start, center);
				gap: 15px;
				svg {
					position: relative;
					top: 3px
				}

				p {
					@include font($main, 20px, 600, normal, normal);
				}
			}
		}

		
	}

	&__rigth {
		width: 40%;
		height: 100%;
		@include display-center(center, center);

		&-img {
			img {
				width: 250px;
			}
		}
	}

}

	

.CoursePage__specific {
	flex-direction: column-reverse;

	.CoursePage__left {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.CoursePage__left-subTitle2 {
			text-align: center !important;
		}

		.CoursePage__left-discount {
			li {
				list-style: inside;
				text-align: center;
			}
		}

		.CoursePage__left-title {
			text-align: center;
			margin-top: 50px;
		}


		.CoursePage__left-description {
			width: 100%;

			p {
				font-weight: 600;
				text-align: center;
			}

			h1 {
				font-weight: 600;
				text-align: center;
				font-size: 24px;
			}

			h2 {
				color: $main;
				font-size: 24px;
				font-weight: 600;

			}


			div {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding-left: 0px;
				padding-right: 0px;
				margin-left: 0px;
				margin-right: 0px;
				gap: 20px;

				
				ul {
					margin-top: 10px;
					

					p {
						text-align: start;
						font-weight: 600;
						color: $main;
					}
					li {
						margin-top: 5px;
						margin-bottom: 10px;
						list-style: inside;
						
					}
				}
			}
		}
		
		.CoursePage__left-discount {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.CoursePage__left-box {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			p {
				text-align: center;
			}
		}

		.CoursePage__buttons {
			width: auto !important;
			display: flex;
			justify-content: center;
		}

		.CoursePage__buttons-box {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;
		}


	

		&-containers {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			@include font($main, 20px, 500, normal, normal);
			flex-wrap: wrap;

			ul {
				display: flex;
				gap: 50px;
				flex-wrap: wrap;
				display: flex;
				align-items: center;
				justify-content: center;

				li {
				list-style: inside;
				text-align: center;

				}
			}

		}
	}

	.CoursePage__right {
		width: 100%;
		padding-right: 0px;
	}
}





@media screen and (max-width: 1350px) {
	.CoursePage {
		padding: 100px 80px;
		padding-top: 20px;

	}
}

@media screen and(max-width: 1250px) {
	.CoursePage {
		&__left {
			width: 60%;
			padding-right: 35px;
			
			&-description {
				@include font($black, 20px, 500, normal, normal);
			}

			&-box {
				gap: 20px;
			}
		}
		&__rigth {
			&-img {
				img {
					width: 300px;
				}
			}
		}
	}

}

@media screen and (max-width: 1000px) {

.CoursePage {
	padding: 100px 60px;
	padding-top: 20px;
	flex-direction: column-reverse;

	&__form {
		width: 100%;
		&-title {
			@include font($black, 32px, 600, normal, normal);
		}

		form {
			width: 100%;
		}

		button {
			width: 100% !important;
		}

		&-input {
			width: 100%;

			input {
				width: 100%;
				@include font($main, 18px, 700, normal, normal);
			}

			textarea {
				width: 100%;
				@include font($main, 18px, 700, normal, normal);
			}

			.form__error {
				@include font(red, 16px, 400, normal, normal);
			}
		}
	}

	&__left {
		width: 100%;
		padding-right: 0px;
		&-title {
			font-size: 36px;
			text-align: center;	
			margin-top: 25px;
		}

		&-description {
			width: 100%;
			text-align: center;	
		}

		&-box {
			gap: 15px;
			@include display-center(center, flex-start);

			
			.CoursePage__item {
				gap: 10px;
				p {
					@include font($main, 18px, 600, normal, normal);
				}
			}
		}
	}

	&__rigth {
		&-img {
			img {
				width: 250px;
			}
		}
	}

}


.CoursePage__specific {
	.CoursePage__left {
		.CoursePage__left-description {

			h2 {
				text-align: start;
			}
			div {
				ul {
					li {
						text-align: start;
						
					}
				}
			}
		}
	}
}



}

@media screen and (max-width: 700px) {
	.CoursePage {
		padding: 60px 30px;
		padding-top: 20px;
	}

	.CoursePage__specific {
		.CoursePage__left {

			.CoursePage__left-title {
				font-size: 28px;
			}

			&-containers {
				width: 80%;
				margin-top: 20px;
				ul {
					flex-direction: column;
					gap: 20px;
				}
			}

			.CoursePage__buttons-box {
				display: flex;
				flex-direction: column;
				gap: 0px;
				.course__buttons {
					margin-top: 30px !important;
				}
			}

			.CoursePage__left-description {
				h2 {
					text-align: center;
				}
				div {
					flex-direction: column;
					ul {
						p {
							text-align: center;
						}
						li {
							text-align: center;
							
						}
					}
				}
			}

			
		.CoursePage__left-box {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			p {
				text-align: center;
				width: 100% !important;
			}
		}
		}
	}

}

@media screen and (max-width: 530px) {
	.CoursePage {
		&__left {
			&-title {
				font-size: 32px;
			}
	
			&-description {
				font-size: 18px;		
			}
			&-box {
				.CoursePage__item {
					flex-direction: column;
					p {
						width: 80%;
						font-size: 16px;
						text-align: center;
					}
				}
			}

			.CoursePage__buttons {
				width: 100%;
				@include display-center(center, center);
				flex-direction: column;
				.course__buttons {
					width: 100%;
				}
			}
		}
		
	
		&__rigth {
			&-img {
				img {
					width: 200px;
				}
		}
	
	}
	}

}