@import "../../main/style.scss";


.ready {
	width: 100%;
	height: auto;
	padding: 0 100px;
	margin-bottom: 100px;

	&__title {
		@include font($black, 48px, 700, normal, normal);
	}
	&__description {
		width: 500px;
		@include font($grey, 28px, 400, normal, normal);
		margin-top: 25px;
	}

	&__buttons {
		width: 100%;
		height: auto;
		@include display-center(flex-start, center);
		gap: 30px;
		margin-top: 50px;

		&-button {
			padding: 0 30px
		}
	}
	
	&__box {
		@include display-center(space-between, center);
		margin-top: 50px;
		&-img {
			width: 450px;
			height: 450px;
			background-image: url(../img/ready__img.webp);
			background-size: cover;
			border-radius: 10px;
		}

		&-container {
			width: auto;
			height: auto;
		}
	}
}


@media screen and (max-width: 1350px) {
	.ready {
		padding: 0 60px;
	
		&__box {
			&-img {
				width: 400px;
				height: 400px;
			}
		}
	}

}

@media screen and (max-width: 1200px) {
	.ready {
		padding: 0 80px;

		&__title {
			@include font($black, 36px, 700, normal, normal);
		}

		&__description {
			color: $black;
		}
		&__box {
			&-img {
				width: 400px;
				height: 400px;
				position: absolute;
				z-index: 0;
				opacity: 0.5;
			}
			&-container {
				z-index: 1;
				padding-left: 20px;
			}
		}
	}
}

@media screen and (max-width: 1000px) {
	.ready {
		padding: 60px 60px;

		&__description {
			text-align: center;
		}

		&__box {
			@include display-center(center, center);
			flex-direction: column;
			&-img {
				width: 500px;
				height: 500px;
				position: absolute;
				z-index: 0;
				opacity: 0.5;
			}
			&-container {
				@include display-center(center, center);
				flex-direction: column;
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.ready {
		padding: 0px 30px;
		&__title {
			@include font($black, 32px, 700, normal, normal);
		}

		&__buttons {
			flex-direction: column;
		}
		&__box {
			@include display-center(center, center);
			flex-direction: column;
			&-img {
				width: 450px;
				height: 450px;
			}
		}
	}
}

@media screen and (max-width: 530px) {
	.ready {
		&__title {
			width: 100%;
			@include font($black, 28px, 700, normal, normal);
			text-align: center;
			margin-bottom: 30px;
		}

		&__description {
			width: 100%;
		}

		&__buttons {
			width: 100%;
			div {
				width: 100%;
			}
			a {
				width: 100%;
			}
			&-button {
				width: 100% !important;
			}
		}
		&__box {
			&-img {
				width: 320px;
				height: 320px;
			}
		}
	}
}