@import "../../main/style.scss";

.catalog {
	width: 100%;
	height: auto;

	&__box {
		width: 100%;
		padding: 100px 100px;
		height: auto;
		@include display-center(center, center);
		flex-wrap: wrap;
	}

	&__link {
		padding-left: 100px;
		padding-top: 100px;
	}


}


@media screen and (max-width: 1350px) {
	.catalog {
		&__box {
		padding: 60px 60px;
	}

	&__link {
		padding-left: 60px;
		padding-top: 60px;
	}

	}

}

@media screen and (max-width: 1200px) {
	.catalog {
		&__box {
		padding: 60px 80px;
	}

	&__link {
		padding-left: 80px;
		padding-top: 80px;
	}

	}

}

@media screen and (max-width: 1000px) {
	.catalog {
		&__box {
		padding: 100px 60px;
	}
	&__link {
		padding-left: 60px;
		padding-top: 60px;
	}

	}
}

@media screen and (max-width: 700px) {
	.catalog {
		&__box {
		padding: 60px 30px;
	}

	&__link {
		padding-left: 30px;
		padding-top: 60px;
	}

	}

}
