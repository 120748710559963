@import "../../main/style.scss";


.home {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 100px;
	flex-direction: column;

	&__crossing {
		width: 100%;
		height: 100vh;
		@include display-center(flex-end, center);
		position: fixed;
		display: none;
		z-index: 14;

		&-popup1 {
			width: 100%;
			height: 100vh;
			background-color: $black;
			position: fixed;
			left: 0%;
			top: 0%;
			z-index: 11;
			transition: 1s;
			
		}

		&-popup1--open {
			width: 0%;
			transition: 1.8s;
			animation-name: popup2animation;
            animation-duration: 1.8s;
            animation-iteration-count: 1;

			@keyframes popup2animation {
				0% {
					width: 100%;
				}
				100% {
					width: 0%;
				}
			}
		}

		&-popup2 {
			width: 100%;
			height: 100vh;
			background-color: $main;
			position: fixed;
			left: 0%;
			top: 0%;
			z-index: 13;
			transition: 1.2s;
		}

		&-popup2--open {
			width: 0%;
			transition: 1.2s;
			animation-name: popup2animation;
            animation-duration: 1.2s;
            animation-iteration-count: 1;

			@keyframes popup2animation {
				0% {
					width: 100%;
				}
				100% {
					width: 0%;
				}
			}

		}

	}

	&__crossing-open {
		display: block;
	}

	&__popup {
		width: 100%;
		height: 0vh;
		background-color: $white;
		position: absolute;
		left: 0%;
		top: 0%;
		z-index: 5;
		transition: 0.5s;
	}

	&__popup-open {
		height: 100vh;
		transition: 0.5s;
	}

	&__header {
		width: 100%;
		height: 15%;
		border-bottom: 1px solid #B41D8A;
		background: rgba(255, 255, 255, 0.10);
		padding: 30px 100px;
		padding-top: 20px;
		padding-bottom: 20px;
		@include display-center(space-between, center);


		&-logo {
			width: 300px;
			z-index: 5;

			.logo {
				width: 80px;
				height: 70px;
				background-image: url(../../main/img/logo.svg);
				background-size: cover;
				z-index: 5;
			}
		}

		&-nav {
			// background-color: red;
			ul {
				@include display-center(center, center);
				@include font($black, 16px, 600, normal, normal);
				gap: 30px;
				cursor: pointer;
				position: relative;
				right: 100px;
			}
		}

		&-box {
			width: 300px;
			@include display-center(flex-end, center);
			gap: 20px;

			select {
				width: auto;
				height: 60px;
				@include display-center(flex-end, center);
				background-color: $main;
				border: none;
				border-radius: 5px;
				box-shadow: 0px 4px 7px 0px $main;
				@include font($white, 18px, 700, normal, normal);
				padding: 10px;
				background-position: 46px;
				background-repeat: no-repeat;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				padding: 0 10px;
			}
		}

		.box__burger {
			display: none;
			z-index: 5;

		}
	}

	&__main {
		width: 100%;
		height: 85%;
		display: flex;
		padding: 60px 100px;
		@include display-center(flex-start, center);

		&-left {
			width: 50%;
			height: 100%;
			@include display-center(center, flex-start);
			flex-direction: column;
			position: relative;

			.home__title {
				width: 95%;
				@include font($black, 42px, 700, normal, normal);
			}

			.home__description {
				@include font($grey, 28px, 400, normal, normal);
				margin-top: 25px;
			}

			.home__button {
				margin-top: 50px;
			}

			.home__star {
				position: absolute;
				right: 20%;
				margin-top: 300px;
			}

			.home__arrow {
				position: absolute;
				right: 0%;
				top: 0%;
				margin-top: 30px;
			}
		}

		&-right {
			width: 50%;
			height: 100%;
			@include display-center(flex-end, center);
			
			.home__elipes {
				width: 544px;
				height: 620px;
				background-image: url(../img/home__bg.webp);
				background-size: cover;
				@include display-center(flex-end, flex-start);
				position: relative;
				transform-style: preserve-3d;

				&::after {
					content: "";
					width: 170px;
					height: 170px;
					background-image: url(../img/dot.svg);
					background-size: cover;
					position: absolute;
					transform: translateZ(-1px);
					margin-top: 50px;
					margin-right: 20px;
				}
			}
		}
	}

}


@media screen and (max-width: 1350px) {
.home {
	&__header {
		padding: 30px 80px;
		&-logo {
			width: 100px;
			height: 85px
		}
		&-nav {
			ul {
				@include display-center(center, center);
				@include font($black, 16px, 600, normal, normal);
				gap: 20px;
				cursor: pointer;
				position: relative;
				right: 10px;
			}
		}
	}
	&__main {
		&-left {
			.home__title {
				@include font($black, 36px, 700, normal, normal);
			}
		}

		&-right {
			.home__elipes {
				width: 450px;
				height: 513px;
			}


		}
	}

}
}


@media screen and (max-width: 1200px) {
	.home {
		&__header {
			padding: 30px 80px;
			&-logo {
				width: 100px;
				display: flex;
				align-items: center;
				.logo {
					width: 70px;
					height: 63px;
				}
			}
	
			&-button {
				height: 50px !important;
				font-size: 18px
			}

			&-nav {
				ul {
					@include font($black, 14px, 600, normal, normal);
					gap: 25px;
				}
			}
	
			&-box {
				@include display-center(flex-end, center);
				gap: 20px;
	
				select {
					width: 110px;
					height: 50px;
					font-size: 14px;
					background-position: 40px;
				}

			}
		}
	
		&__main {
			padding: 60px 80px;
			&-left {
				.home__title {
					@include font($black, 32px, 700, normal, normal);
				}
				.home__description {
					@include font($grey, 24px, 400, normal, normal);
				}

				.home__star {
					position: absolute;
					right: 0%;
					margin-top: 300px;
				}
			}
			&-right {
				.home__elipes {
					width: 412px;
					height: 470px;
				}
			}
		}
	
	}
}



@media screen and (max-width: 1100px) {
	.home {
		height: auto;
		&__header {
			padding: 20px 60px;
	
			&-button {
			display: none;
			}

			&-nav {
				display: none
			}

			
			&-nav--open {
				width: 100%;
				height: 100vh;
				position: absolute;
				left: 0%;
				top: 0%;
				display: block !important;
				z-index: 5 !important;
				@include display-center(center, center);
				ul {
					width: 100%;
					height: 100%;
					@include display-center(center, center);
					flex-direction: column;
					animation-name: navigation;
					animation-duration: 0.8s;
					animation-iteration-count: 1;

					@keyframes navigation {
						0% {
							position: relative;
							bottom: 50px;
							opacity: 0;
						}

						80% {
							position: relative;
							bottom: 50px;
							opacity: 0;
						}
						100% {
							position: relative;
							bottom: 0px;
							opacity: 1;
						}
					}

					li {
						font-size: 24px;
						z-index: 12 !important;
					}

				}
			}


			.box__burger {
				display: block;
				width: 50px;
				height: 50px;
				@include display-center(space-around, center);
				flex-direction: column;
				display: flex;
				overflow: hidden;
				position: relative;
				top: 2px;

				&-row {
					width: 100%;
					height: 2px;
					background-color: $main;
					transition: 0.5s;
				}

				&-row2 {
					transition: 0.5s;
					position: relative;
					right: 0px;

				}

				&-row1Show {
					transition: 0.5s;
					transform: rotate(-45deg) translate(-15px, 13px);
				}

				&-row2Show {
					transition: 0.5s;
					position: relative;
					right: 100px;

				}

				&-row3Show {
					transition: 0.5s;
					transform: rotate(45deg) translate(-10px, -9px);
				}
				
			}

		}
	
		&__main {
			padding: 0 60px;
			flex-direction: column;
			&-left {
				width: 100%;
				height: auto;
				@include display-center(flex-start, center);
				flex-direction: column;
				padding-top: 80px;
				.home__title {
					width: 80%;
					text-align: center;
				}
				.home__description {
					text-align: center;
				}

				.home__star {
					position: absolute;
					right: 0%;
					margin-top: 300px;
				}
			}
			&-right {
				width: 100%;
				height: auto;
				@include display-center(center, center);
				margin-top: 40px;

				.home__elipes {
					width: 412px;
					height: 470px;
				}
			}
		}
	
	}
}






@media screen and (max-width: 700px) {
	.home {
		height: auto;
		&__header {
			padding: 20px 30px;
		}
	
		&__main {
			padding: 0 30px;
			&-left {
				padding-top: 80px;
				.home__title {
					width: 100%;
					@include font($black, 28px, 700, normal, normal);
					text-align: center;
				}

				.home__star {
					position: absolute;
					right: 20%;
					margin-top: 350px;
				}

			}
			&-right {
				.home__elipes {
					width: 350px;
					height: 400px;
				}
			}
		}
	
	}
	
}


@media screen and (max-width: 530px) {
	.home {
		&__main {
			&-left {
				padding-top: 80px;
				.home__title {
					width: 100%;
					@include font($black, 24px, 700, normal, normal);
					text-align: center;
					z-index: 4;
				}

				.home__description {
					@include font($grey, 20px, 400, normal, normal);
					margin-top: 20px;
				}

				.home__star {
					position: absolute;
					right: 85%;
					bottom: 80%;
					margin-top: 350px;
				}

				.home__button {
					width: 100% !important;
					margin-top: 40px;
					padding: 0 5px;
				}
	
				.home__arrow {
					z-index: 2;
				}

			}
			&-right {
				.home__elipes {
					width: 270px;
					height: 309px;
				}
			}
		}
	
	}
}