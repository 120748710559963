@import "../../main/style.scss";

.store {
	width: 100%;
	height: 100%;
	margin-bottom: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__title {
		width: 100%;
		@include font($black, 48px, 700, normal, normal);
		text-align: center;
		margin-bottom: 40px;
	}

	&__container {
		width: 100%;
		height: auto;
		background-color: $main;
		margin-bottom: 80px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 80px 100px;
		gap: 20px;
	
		&-card {
			width: 250px;
			height: 350px;
			border-radius: 20px;
			background-color: $white;
			display: flex;
			justify-content: center;
			align-items: center;

			&--book{
				width: 200px;
				height: 300px;
				background-size: cover;
				border-radius: 10px;
			}

			&--book1{
				background-image: url(../img/book3.png);
			}
			&--book2{
				background-image: url(../img/book1.png);
			}
			&--book3{
				background-image: url(../img/book2.png);
			}
			&--book4{
				background-image: url(../img/book4.png);
			}
		}
	}

}

@media screen and (max-width: 1350px) {
	.store {
		&__title {
			width: 100%;
			@include font($black, 42px, 700, normal, normal);
		}
		&__container {
			padding: 60px 100px;
			display: grid;
			grid-template-columns: 1fr 1fr; 
			grid-template-rows: 1fr 1fr; 
			gap: 40px; 
			justify-items: center; 
			align-items: center; 
		}
	}

}

@media screen and (max-width: 700px) {
	
	.store {
		&__title {
			width: 100%;
			@include font($black, 36px, 700, normal, normal);
			padding: 0 30px;
		}
		&__container {
			padding: 30px 100px;
			display: flex;
			flex-direction: column;
			margin-bottom: 50px;

			&-cardHidden {
				display: none;
			}
		}

		&__button {
			width: 100% !important;
		}

		a {
			width: 100%;
			padding: 0 30px;
		}
	}

}