@import "../../main/style.scss";

.teamDetails {
	width: 100%;
	height: auto;
	background-size: cover;
	@include display-center(flex-start, center);
	flex-direction: column;

	&__buttons {
		width: 100%;
		height: auto;
		@include display-center(center, center);
		margin-bottom: 140px;	
	}

	&__box {
	width: 100%;
	padding: 100px 100px;

		&-title {
			@include font($main, 48px, 700, normal, normal);
			margin-top: 50px;
		}

		&--container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 15px;
			margin-top: 50px;
		}
	}
}

.details__container {
	width: 100%;
	.team__block {
		width: 100%;
		height: 70px;
		background-color: $main;
		border-radius: 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	
		box-shadow: 0px 4px 7px rgba(180, 29, 138, 1);
		cursor: pointer;
		padding: 0 30px;

		div {
			display: flex;
			align-items: center;
			gap: 10px;

			.team__block--title {
				@include font($white, 32px, 600, normal, normal);
			}
		}
	}

	.team__text {
		width: 100%;
		margin-top: 25px;
		
		@include font($black, 24px, 500, normal, normal);
	}

	.team__text-quotes {
		display: flex;
		gap: 5px;
		&::after {
			content: "❞";
		}
		&::before {
			content: "❝";
		}
	}
}


@media screen and (max-width: 1350px) {
	.privacy {
		&__main {
			padding: 100px 80px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.gallery {
		&__box {
			padding: 80px 60px;
			&-title {
				font-size: 36px;
				text-align: center;	
				margin-top: 25px;
			}
		}
	}

	.details__container {
		.team__block {
			padding: 0 20px;
			div {
				.team__block--title {
					@include font($white, 28px, 600, normal, normal);
				}
			}
		}
	}
	
}
@media screen and (max-width: 700px) {

	.gallery__buttons {
		padding: 0 30px;
	}
	.team__box--container {
		gap: 30px;
	}

	.details__container {
		img {
			width: 32px;
			height: 32px;
		}
		.team__block {
			padding: 0 10px;
			div {
				.team__block--title {
					@include font($white, 24px, 600, normal, normal);
				}
			}
		}
		.team__text {
			margin-top: 15px;
			@include font($black, 20px, 500, normal, normal);
		}
	}

}
