@import "../../main/style.scss";


.home-cards {
	width: 100%;
	height: auto;
	padding: 0 100px;
	margin-bottom: 100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__card {
		width: calc(33.33%); 
		padding: 20px;
		background-color: $main;
		@include display-center(center, center);
		@include font($white, 20px, 600, normal, normal);
		box-sizing: border-box;
		text-align: center;
	}

	&__first {
		border-radius: 10px 0 0 0;
	}

	&__last {
		border-radius: 0 0 10px 0;
	}
}



@media screen and (max-width: 1350px) {
	.home-cards {
		padding: 0 60px;
	}

}

@media screen and (max-width: 1200px) {
	.home-cards {
		padding: 0 80px;
	}
}

@media screen and (max-width: 1000px) {
	.home-cards {
		padding: 0 60px;
		&__card {
			width: calc(50% - 20px); 
		}
	}
}

@media screen and (max-width: 700px) {
	.home-cards {
		width: 100%;
		padding: 0 30px;

		display: flex;
		flex-direction: column;
		&__card {
			width: 100%; 
			margin-bottom: 0px; 
			padding: 20px;
			background-color: $main;
			@include display-center(center, center);
			@include font($white, 20px, 600, normal, normal);
			box-sizing: border-box;
			text-align: center;
			border-radius: 0px;
			div {
				width: 100%;
			}
		}
	
		&__first {
			border-radius: 10px 10px 0 0;
		}
	
		&__last {
			border-radius: 0 0 10px 10px;
		}

	}
}

@media screen and (max-width: 530px) {
	.home-cards {
		&__card {
			padding: 15px;
			font-size: 18px;
		}
	}
}