@import "../../main/style.scss";

.gallery {
	width: 100%;
	height: auto;
	background-size: cover;
	@include display-center(flex-start, center);
	flex-direction: column;

	&__buttons {
		width: 100%;
		height: auto;
		@include display-center(center, center);
		margin-bottom: 140px;	
	}

	&__box {
	width: 100%;

	padding: 100px 100px;

		&-title {
			@include font($main, 48px, 700, normal, normal);
			margin-top: 50px;
		}

		.gallery__container {
			width: 100%;
			margin-top: 50px;
			display: flex;
			flex-wrap:wrap;
			column-gap: 20px;
			gap: 20px;

			img {
				width: 400px;
				height: 550px;
			}

			.img-python {
				width: 400px;
				height: 260px;
			}



			.img11 {
				width: 300px;
				height: 200px;
			}



		}
	}
}


@media screen and (max-width: 1350px) {
	.privacy {
		&__main {
			padding: 100px 80px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.gallery {
		&__box {
			padding: 80px 60px;
			&-title {
				font-size: 36px;
				text-align: center;	
				margin-top: 25px;
			}
		}
	}
}
@media screen and (max-width: 700px) {

	.gallery__buttons {
		padding: 0 30px;
	}

	.gallery__buttons-button {
		width: 100% !important;
	}

	.gallery {
		&__box {
			padding: 60px 30px;
			&-title {
				font-size: 32px;
			}

			.gallery__container {
				@include display-center(center, center);
				img {
					width: 80vw;
					height: 85vw;

				}

				.img-python {
					width: 80vw;
					height: 55vw;
				}
			}
		}
		
	}
}
