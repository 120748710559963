@import "../../main/style.scss";

.team {
	width: 100%;
	height: auto;
	background-size: cover;
	@include display-center(flex-start, center);
	flex-direction: column;

	&__buttons {
		width: 100%;
		height: auto;
		@include display-center(center, center);
		margin-bottom: 140px;	
	}

	&__box {
	width: 100%;
	padding: 100px 100px;

		&-title {
			@include font($main, 48px, 700, normal, normal);
			margin-top: 50px;
		}

		&--container {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			gap: 45px;
			margin-top: 50px;

			.team__card {
				width: 310px;
				height: 430px;
				background-color: $main;
				border-radius: 10px;
				padding: 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;

				&--img {
					width: 300px;
					height: 210px;
				}

				&--box {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-top: 10px;
					gap: 5px;
					padding: 0 5px;
				}

				&--title {
					@include font($white, 20px, 600, normal, normal);
					text-align: center;
				}

				&--description {
					@include font($white, 16px, 400, normal, normal);
					text-align: center;
				}

				&--bottom {
					width: 100%;
				}

				button {
					width: 100%;
					height: 55px;
					background-color: $white;

					display: flex;
					align-items: center;
					justify-content: center;
					
					@include font($main, 16px, 600, normal, normal);
					transition: 0.5s;


					&:hover {
						background-color: $secondary;
						color: $white;
						transition: 0.5s;
					}
				}


			}
		}
		
		
	}
}


@media screen and (max-width: 1350px) {
	.team {
		&__box {
			padding: 100px 80px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.team {
		&__box {
			padding: 80px 60px;
		}
	}
}
@media screen and (max-width: 800px) {
	.team {
		&__box {
			&--container {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				gap: 45px;
				margin-top: 50px;
	
				.team__card {
					width: 100%;
					height: 430px;
					background-color: $main;
					border-radius: 10px;
					padding: 15px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
	
					&--img {
						width: 300px;
						height: 210px;
					}
	
					&--box {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 10px;
						gap: 5px;
						padding: 0 5px;
					}
	
					&--title {
						@include font($white, 20px, 600, normal, normal);
						text-align: center;
					}
	
					&--description {
						@include font($white, 16px, 400, normal, normal);
						text-align: center;
					}
	
					&--bottom {
						width: 100%;
					}
	
					button {
						width: 100%;
						height: 55px;
						background-color: $white;
	
						display: flex;
						align-items: center;
						justify-content: center;
						
						@include font($main, 16px, 600, normal, normal);
						transition: 0.5s;
	
	
						&:hover {
							background-color: $secondary;
							color: $white;
							transition: 0.5s;
						}
					}
	
	
				}
			}
		}
	}
}

@media screen and (max-width: 700px) {
	.team {
		&__box {
			padding: 60px 30px;
		}
	}
}
