@import "../../../main/style.scss";


.faq__card {
    border-radius: 5px;
    background: $white;
    border-radius: 5px;
    background: #FFF;
    // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);

    padding: 0 25px;
    width: 100%;
    border-radius: 5px;

    overflow: hidden;
    height: 90px;

    transition: height .6s; 

    &._open {
        transition: height .6s; 
    }


    &-question {
        width: 100%;
        height: 100px;
        
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &-text {
            @include font($black, 44px, 500, normal, 0); 
        }

        .faq__card-btn {
            width: 70px; 
            height: 70px; 
            background-color: $main;
            border-radius: 50%;
    
            @include display-center(center, center);
            cursor: pointer;
            transition: .4s;

            & svg {
                width: 70px;
                height: 70px;
            }

            &._active {
                transform: rotate(135deg);
            }
        }
    }

    &-dropdown-text {
        transition: height 0.6s; 
        @include font($black, 32px, 400, normal, 0);
    }
}

@media screen and (max-width: 2000px) {
    .faq__card {
        height: 110px;

        &-question {
            height: 110px;

            &-text {
                font-size: 40px;
            }

            .faq__card-btn {
                width: 66px;
                height: 66px;
    
                & svg {
                    width: 66px;
                    height: 66px;
                }
            }
        }

        
        &-dropdown-text {
            font-size: 28px;
        }
    }
}


@media screen and (max-width: 1700px) {
    .faq__card {
        padding: 0 25px;
        height: 85px;

    
        &-question {
            width: 100%;
            height: 100px;
            
            &-text {
                font-size: 32px;
            }
    
            .faq__card-btn {
                width: 60px;
                height: 60px;
    
                & svg {
                    width: 52px;
                    height: 52px;
                }
            }
        }
    
        &-dropdown-text {
            font-size: 24px;
        }
    }
}


@media screen and (max-width: 1200px) {
    .faq__card {
        &-question {
            &-text {
                font-size: 28px;
            }
        }

        &-dropdown-text {
            font-size: 18px;
        }
    }
}


@media screen and (max-width: 800px) {
    .faq__card {
        padding: 0 20px;
        height: 80px;

        &-question {
            margin-bottom: 10px;
            height: 90px;

            &-text {
                font-size: 24px;
            }
        }

        &-dropdown-text {
            font-size: 18px;
        }
    }
}


@media screen and (max-width: 500px) {
    .faq__card {
        padding: 0 15px;

        &-question {
            column-gap: 10px;

            &-text {
                font-size: 20px;
                max-width: 70%;
            }

            .faq__card-btn {
                width: 48px;
                height: 48px;

                & svg {
                    height: 40px;
                    width: 40px;
                }
    
                &._active {
                    transform: rotate(135deg);
                }
            }
        }
    }
}