@import "./mixins";
@import "./variables";


.body-hidden {
    max-height: 100vh;
    overflow: hidden;
}

body {
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
}

.link__web {
	@include font($black, 20px, 400, normal, normal);
	cursor: pointer;
	display: flex;
	align-items: center;
	
	&-margin {
		margin: 0 10px;
	}
}
